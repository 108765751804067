export const getCookie = (name: string): string | undefined => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop()?.split(";").shift()
}

export const setCookie = (key: string, value: string): void => {
    const date = new Date()
    const expirePeriod = 30 * 24 * 60 * 60 * 1000
    date.setTime(date.getTime() + expirePeriod)
    const expires = "expires=" + date.toUTCString()
    let cookie = `${key}=${value};${expires};path=/;domain=${import.meta.env.VITE_COOKIE_DOMAIN};`
    if (import.meta.env.MODE !== "development") {
        cookie += ";samesite=none;secure"
    } else {
        cookie += ";lax"
    }
    document.cookie = cookie
}

export const deleteCookie = (key: string): void => {
    let cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${import.meta.env.VITE_COOKIE_DOMAIN};`
    if (import.meta.env.MODE !== "development") {
        cookie += ";samesite=none;secure"
    } else {
        cookie += ";lax"
    }
    document.cookie = cookie
}
