const TransitLines = () => import("@/views/companiesAndTerritories/TransitLines.vue")

const routes = [
    {
        path: "/admin/transit-lines",
        name: "transitLinesIndex",
        component: TransitLines,
        meta: {
            label: "Transit lines"
        }
    }
]

export default routes
