import { createStore } from "vuex"
import Users from "@/store/modules/users"

/* Companies and territories */
import Partners from "@/store/companiesAndTerritories/partners"
import pricing from "@/store/companiesAndTerritories/pricing"

/* Match */
import match from "@/store/match/match"

/* Various */
import userFinder from "@/store/various/userFinder"

export const store = createStore({
    modules: {
        Users,
        Partners,
        pricing,
        userFinder,
        match
    }
})
