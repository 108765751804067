<template>
    <BaseHeader logo-redirection="/admin/">
        <template #prepend>
            <v-btn
                :icon="menuStore.isMenuExpanded ? 'mdi-menu-open' : 'mdi-menu'"
                variant="text"
                color="#666"
                density="comfortable"
                @click="menuStore.toggleMenuExpansion()" />
        </template>
        <template #append>
            <div class="user-info-container">
                <TeamSpaceSelector />
                <el-button text @click="disconnect()"> Log out </el-button>
            </div>
        </template>
    </BaseHeader>
</template>

<script lang="ts" setup>
import BaseHeader from "@/components/core/BaseHeader.vue"
import TeamSpaceSelector from "@/components/global/TeamSpaceSelector.vue"
import { useMenuStore } from "@/store/core/menu"
import { useAuthStore } from "@/store/core/auth"

const menuStore = useMenuStore()
const authStore = useAuthStore()
const disconnect = () => {
    authStore.logout()
}
</script>

<style lang="scss" scoped>
.user-info-container {
    display: flex;
    align-items: center;
    column-gap: 17px;
    .country-flag {
        height: 32px;
        object-fit: contain;
        border: 2px solid #ffffff;
        border-radius: 5px;
    }
    .app-logo {
        height: 24px;
    }
}
</style>
<style lang="scss">
@use "@/styles/colors";

.menu-links-list-wrapper {
    .v-list-item--variant-text .v-list-item__overlay {
        color: colors.$primary !important;
    }
}
</style>
