import { defineStore } from "pinia"
import { ref } from "vue"

export const useHeadStore = defineStore("head", () => {
    const suffix = import.meta.env.VITE_VIEW_TITLE_SUFFIX

    const tabTitle = ref("Admin API")

    function setTitle(title: string) {
        tabTitle.value = title || "Admin API"
        document.title = `${tabTitle.value} ${suffix}`
    }
    return {
        setTitle
    }
})
