<template>
    <v-navigation-drawer
        class="app-menu-container bg-grey-lighten-4 elevation-1 display-menu-over-body"
        :rail="!menuStore.isMenuExpanded"
        :expand-on-hover="!menuStore.isMenuExpanded"
        rail-width="72"
        permanent
        floating
        data-testid="app-menu-root"
        width="260"
        @update:rail="menuStore.railModeChanged">
        <v-window v-model="menuToDisplay" class="px-2 pt-8 pb-3">
            <v-window-item :value="MenuType.DEFAULT" class="default-menu" data-testid="default-menu">
                <v-text-field
                    v-model="searchInput"
                    class="mb-4 search-input"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    variant="solo"
                    rounded="lg"
                    density="compact"
                    hide-details
                    clearable
                    autofocus />
                <v-list v-model:opened="menuStore.openFavoriteMenus">
                    <v-list-item
                        class="home-link mb-1"
                        :prepend-icon="getHomeIcon"
                        to="/admin/"
                        color="primary"
                        rounded="lg"
                        density="compact"
                        data-testid="home-link-item">
                        <v-list-item-title class="text-subtitle-2"> Home </v-list-item-title>
                    </v-list-item>
                    <v-list-group value="favorites" data-testid="favorite-group">
                        <template #activator="{ props }">
                            <v-list-item
                                v-bind="props"
                                :prepend-icon="isFavoriteGroupActive ? 'mdi-star' : 'mdi-star-outline'"
                                density="compact"
                                :active="isFavoriteGroupActive"
                                rounded="lg"
                                color="primary"
                                data-testid="favorite-group-activator">
                                <v-list-item-title class="text-subtitle-2"> Favorites </v-list-item-title>
                            </v-list-item>
                        </template>
                        <v-hover v-for="link in filteredFavorites" v-slot="{ isHovering, props }" :key="link.id">
                            <v-list-item
                                :key="link.title"
                                :href="sanitizeUrl(link.path)"
                                density="compact"
                                :active="isLinkActive(link.path)"
                                rounded="lg"
                                color="primary"
                                v-bind="props"
                                data-testid="favorite-item">
                                <v-list-item-title class="text-subtitle-2">
                                    {{ link.title }}
                                </v-list-item-title>
                                <template #append>
                                    <v-icon
                                        v-show="isHovering"
                                        icon="mdi-star"
                                        data-testid="delete-fav-icn"
                                        @click.prevent="deleteFavorite(link.id)" />
                                </template>
                            </v-list-item>
                        </v-hover>
                    </v-list-group>
                </v-list>
                <v-divider class="py-2 mx-4" />
                <v-list v-model:opened="menuStore.openMenus">
                    <v-list-group
                        v-for="menuGroup in filteredMenu"
                        :key="menuGroup.value"
                        :value="menuGroup.value"
                        data-testid="group-item">
                        <template #activator="{ props }">
                            <v-list-item
                                :key="menuGroup.title"
                                v-bind="props"
                                :prepend-icon="menuGroup.icon"
                                density="compact"
                                :active="isMenuGroupActive(menuGroup)"
                                rounded="lg"
                                color="primary"
                                data-testid="group-item-activator">
                                <v-list-item-title class="text-subtitle-2">
                                    {{ menuGroup.title }}
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                        <v-hover
                            v-for="(link, index) in menuGroup.links"
                            :key="`${link.title}-${index}`"
                            v-slot="{ isHovering, props }">
                            <v-list-item
                                :key="link.title"
                                :to="link.to ?? undefined"
                                :href="link.href ? sanitizeUrl(`${link.href}`) : undefined"
                                density="compact"
                                :active="isLinkActive(link.to?.path)"
                                rounded="lg"
                                color="primary"
                                data-testid="link-item"
                                v-bind="props"
                                @click="updateMenuType(link.menuToDisplay)">
                                <v-list-item-title class="text-subtitle-2">
                                    {{ link.title }}
                                </v-list-item-title>
                                <template #append>
                                    <v-icon
                                        v-show="isHovering"
                                        :icon="getFavoriteIcon(link)"
                                        data-testid="link-item-fav-icn"
                                        @click.prevent.stop="toggleFavorite(link)" />
                                    <v-icon
                                        v-if="link.menuToDisplay"
                                        v-show="isHovering"
                                        icon="mdi-arrow-right-circle"
                                        class="ml-3"
                                        data-testid="menu-nav-icn" />
                                </template>
                            </v-list-item>
                        </v-hover>
                    </v-list-group>
                </v-list>
            </v-window-item>
            <v-window-item :value="MenuType.TICKETING" data-testid="ticketing-menu">
                <TicketingDrawer @update-menu-type="updateMenuType(MenuType.DEFAULT)" />
            </v-window-item>
        </v-window>
    </v-navigation-drawer>
</template>

<script async setup lang="ts">
import { ref, computed, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import cloneDeep from "lodash.clonedeep"
import Mark from "mark.js"
import { sanitizeUrl } from "@braintree/sanitize-url"
import { MenuGroup, MenuLink, FavoritePage } from "@/models/menus/menu"

import { MENU_GROUPS } from "@/constants/menus/menu"
import { MenuType } from "@/constants/enums/index"
import { useMenuStore } from "@/store/core/menu"
import { normalizeString, computeExternalLink } from "@/lib/utils"

import TicketingDrawer from "@/components/crm/ticketing/TicketingDrawer.vue"
import { onMounted, nextTick, onBeforeUnmount } from "vue"
import { appMenuEventHub } from "@/lib/eventHub"
import { useAuthStore } from "@/store/core/auth"

const router = useRouter()
const route = useRoute()
const menuStore = useMenuStore()
const authStore = useAuthStore()

const getHomeIcon = computed<string>(() => {
    return route.path === "/admin/" ? "mdi-home" : "mdi-home-outline"
})

let markInstance: Mark
onMounted(() => {
    appMenuEventHub.on("menu-changed", updateMenuType)
    const markContext: HTMLElement | null = document.querySelector(".app-menu-container")
    if (markContext) {
        markInstance = new Mark(markContext)
    }
})
onBeforeUnmount(() => {
    appMenuEventHub.off("menu-changed", updateMenuType)
})
const searchInput = ref("")
watch(searchInput, () => {
    if (!markInstance) return
    nextTick(() => {
        markInstance.unmark()
        markInstance.mark(searchInput.value || "")
    })
    menuStore.openMenus = filteredMenu.value.map((menuGroup: MenuGroup) => menuGroup.value)
})

const fullMenuForAdminUser = computed<MenuGroup[]>(() => {
    return MENU_GROUPS.map((menuGroup: MenuGroup) => {
        menuGroup.links = getFilteredLinksByCountry(menuGroup.links)
        menuGroup.links = getFilteredLinksByAdminUserAuthorization(menuGroup.links)
        return menuGroup
    })
})
const normalizedSearch = computed<string>(() => normalizeString(searchInput.value))
const filteredMenu = computed<MenuGroup[]>(() => {
    let fullMenu = cloneDeep(fullMenuForAdminUser.value).map(computedExternalUrl)

    fullMenu = fullMenu.filter((menuGoup: MenuGroup) => {
        if (normalizeString(menuGoup.title).includes(normalizedSearch.value)) {
            return true
        } else {
            menuGoup.links = menuGoup.links.filter((link: MenuLink) =>
                normalizeString(link.title).includes(normalizedSearch.value)
            )
            return menuGoup.links.length > 0
        }
    })
    return fullMenu
})

const favoritesSortedAlphabetically = computed<FavoritePage[]>(() => {
    return menuStore.favorites.sort((fav1, fav2) => fav1.title.localeCompare(fav2.title))
})
const filteredFavorites = computed<FavoritePage[]>(() => {
    return favoritesSortedAlphabetically.value.filter(fav =>
        normalizeString(fav.title).includes(normalizedSearch.value)
    )
})

const computedExternalUrl = (menuGroup: MenuGroup) => {
    menuGroup.links = menuGroup.links.map(link => {
        if (link.href) {
            link.href = computeExternalLink(link.href)
        }
        return link
    })
    return menuGroup
}

const getFilteredLinksByCountry = (links: MenuLink[]): MenuLink[] => {
    return authStore.isFrenchAdminUser ? links : links.filter((link: MenuLink) => !link.franceOnly)
}

const getFilteredLinksByAdminUserAuthorization = (links: MenuLink[]): MenuLink[] => {
    return links.filter((link: MenuLink) => {
        if (!link.adminUserAuthorizations || link.adminUserAuthorizations.length === 0) {
            return true
        } else {
            return link.adminUserAuthorizations.some(authorization => {
                return authStore.adminUser && authStore.adminUser[authorization]
            })
        }
    })
}

const isMenuGroupActive = (menuGroup: MenuGroup): boolean => {
    return menuGroup.links.some((menuLink: MenuLink) => {
        return menuLink.to && route.path.includes(menuLink.to.path)
    })
}
const isLinkActive = (routePath?: string): boolean => {
    return !!routePath && route.path.includes(routePath)
}

menuStore.getFavoritePages().then(async () => {
    await router.isReady()
    menuStore.openFavoriteMenus.push("favorites")
    if (route.path.includes("/admin/ticketing")) {
        menuToDisplay.value = MenuType.TICKETING
    }
})
const isFavoriteGroupActive = computed<boolean>(() => menuStore.favorites.some(fav => route.path.includes(fav.path)))
const getFavoriteIcon = (menuLink: MenuLink): string => {
    return isFavorite(menuLink) ? "mdi-star" : "mdi-star-outline"
}

const isFavorite = (menuLink: MenuLink): boolean => {
    const path = menuLink.href || menuLink.to?.path
    return menuStore.favorites.some((fav: FavoritePage) => fav.path === path)
}

const addFavorite = (menuLink: MenuLink): void => {
    let path
    if (menuLink.to) {
        path = menuLink.to.path
    } else if (menuLink.href) {
        path = computeExternalLink(menuLink.href)
    }
    if (path) {
        const favoritePayload: Omit<FavoritePage, "id"> = {
            path,
            title: menuLink.title
        }
        menuStore.addFavorite(favoritePayload)
    }
}
const toggleFavorite = (menuLink: MenuLink): void => {
    if (isFavorite(menuLink)) {
        const favoriteId = getFavoriteId(menuLink)
        if (favoriteId) {
            deleteFavorite(favoriteId)
        }
    } else {
        addFavorite(menuLink)
    }
}
const getFavoriteId = (menuLink: MenuLink): number | undefined => {
    const path: string | undefined = menuLink.href || menuLink.to?.path
    return menuStore.favorites.find(fav => fav.path === path)?.id
}
const deleteFavorite = (favoriteId: number): void => {
    menuStore.deleteFavorite(favoriteId)
}

const menuToDisplay = ref("default")
const updateMenuType = (menuType?: MenuType): void => {
    if (!menuType) return
    menuToDisplay.value = menuType
}
</script>

<style lang="scss">
.app-menu-container .v-window {
    height: 100%;
    overflow-y: auto !important;
    .ticketing-menu {
        height: 100%;
    }
    .v-window__container {
        height: 100% !important;
        .v-window-item {
            height: 100%;
        }
    }
}
.app-menu-container.v-navigation-drawer {
    position: relative !important;
    .v-list-item {
        .v-list-item__spacer {
            width: 12px;
        }
    }
    .default-menu {
        .v-list-item {
            &:not(.v-list-group__header):not(.home-link) {
                padding-inline-start: 52px !important;
            }
        }
    }
    &.v-navigation-drawer--is-hovering.v-navigation-drawer--rail {
        box-shadow:
            0 1px 14px 0 rgba(0, 0, 0, 0.12),
            0 5px 8px 0 rgba(0, 0, 0, 0.12),
            0 3px 5px -1px rgba(0, 0, 0, 0.12) !important;
    }
}
.search-input .v-field {
    padding-left: 16px;
}
</style>
