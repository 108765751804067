import Api from "@/lib/Api"

interface State {
    users: Array<any>
    activeUserUuid: any
}

const USERS = {
    state: {
        users: [],
        activeUserUuid: ""
    } as State,
    actions: {
        getUserDetails({}, userUuid: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/users/${userUuid}/`)
                    .then(userDetails => {
                        resolve(userDetails)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        getDriverLicensesToValidate({}) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/driver_license_manual_validation/`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        updateDriverLicense({}, params: object) {
            return new Promise((resolve, reject) => {
                Api.post(`/admn/driver_license_manual_validation/`, params)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        setDriveLicenseToManualValidation({}, documentId: number) {
            return new Promise((resolve, reject) => {
                Api.post(`/admn/driver_license_manual_validation/${documentId}/assign/`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        updateBonusState({}, userUuid: string) {
            return new Promise((resolve, reject) => {
                const params = {
                    user_uuid: userUuid
                }
                Api.post(`/admn/cee/update_bonus/`, params)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        }
    },
    mutations: {
        updateUsers(state: State, list: any) {
            state.users = list
        },
        clearUsers(state: State) {
            state.users = []
        },
        updateActiveUserUuid(state: State, uuid: any) {
            state.activeUserUuid = uuid
        }
    },
    getters: {
        getUsers: (state: State) => state.users,
        getUser: (state: State) => (user_uuid: any) => {
            return state.users.find(user => user.user_uuid === user_uuid)
        },
        getActiveUser: (state: State) => state.users.find(el => el.user_uuid === state.activeUserUuid)
    }
}
export default USERS
