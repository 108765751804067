const Segments = () => import("@/views/other/Segments.vue")

const routes = [
    {
        path: "/admin/segments/",
        name: "Segments",
        component: Segments,
        meta: {
            label: "Segments"
        }
    }
]

export default routes
